import React, { useRef, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { Link } from "gatsby"

import { useOnScreen } from "hooks/"
import Context from "context/"
import ContentWrapper from "styles/contentWrapper"

const spot = 'https://hrflow-ai.imgix.net/backgrounds/group-spot.svg'

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 1rem;
  background-image: url(${spot});
  background-size: 37.1rem 58.9rem;
  background-position: 100% 17rem;
  background-repeat: no-repeat;
  .more {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3rem 0 0 0;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      color: ${({ theme }) => theme.colors.primary};
      text-align: center;
      margin-top: 0;
      padding: 0 1rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  .card {
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    .card__body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 4.65rem);
      .author {
        line-height: 2.8rem;
        font-size: 1.5rem;
        letter-spacing: -.05rem;
        margin-bottom: 1rem;
      }
      .text {
        padding: 0 2rem;
      }
    }
    .card__footer {
      height: 4.65rem;
      border-top: 2px solid rgba(255, 255, 255, .3);
      line-height: normal;
      font-size: 1.2rem;
      letter-spacing: -.05rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
    }
    a.card__footer {
      text-decoration: underline;
    }
  }
  .card-secondary {
    background: radial-gradient(30.15rem at 70.85% 50.38%, #00d8e6 0, #009bc4 100%);
    width: 29.8rem;
    max-width: 100%;
    height: 29.65rem;
    color: ${({ theme }) => theme.colors.white};
    .card__body {
      flex-wrap: wrap;
      @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
        flex-wrap: nowrap;
      }
      .item {
        padding: 1rem;
        .number {
          font-weight: 700;
          font-size: 5rem;
          line-height: 5rem;
          margin-bottom: 1.5rem;
        }
      }
    }
    .card__footer {
      color: #fff;
      font-weight: 600;
    }
  }

  .card-primary {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 1rem 2.5rem rgba(132, 156, 156, .15);
    max-width: 100%;
    width: 29.8rem;
    height: unset;
    padding-top: 2rem;
    margin-top: 2rem;
    .card__body {
      flex-direction: column;
      justify-content: flex-start;
      .text {
        max-width: 500px;
        padding-bottom: 1rem;
      }
    }
    .card__footer {
      border-top: .1rem solid #d8ecf3;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 29.65rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-gap: 2rem;
    justify-items: center;
    .card-secondary {
      min-width: 25rem;
      width: 100%;
      margin-right: 1rem;
    }
    .card-primary {
      min-width: 25rem;
      width: 100%;
      margin-left: 1rem;
      margin-top: 0;
    }
  }
}
`

const Avatar = styled.div`
  border: 0.15rem solid ${({ theme }) => theme.colors.secondary};
  box-sizing: border-box;
  border-radius: 50%;
  height: 8.6rem;
  width: 8.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .photo {
    height: 7.5rem;
    width: 7.5rem;
    border-radius: 50%;
  }
`
const StyledLargeButton = styled(Link)`
  padding: 3rem 0;
  margin: 3rem auto;
  background-color: #fff;
  border-color: #fff;
  border: .1rem solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.9rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: .065rem;
  padding: 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
`

const Testimonial = ({ content, slug }) => {
  const { frontmatter, body } = content[0].node
  const tControls = useAnimation()
  const iControls = useAnimation()
  const image = getImage(frontmatter.image)
  // Required for animating the text content
  const [ tRef, tInView, tEntry ] = useInView({threshold: 0});
  const [ iRef, iInView, iEntry ] = useInView({threshold: 0});


  // Only trigger animations if the intro is done or disabled
  useEffect(() => {
    if (tInView) tControls.start({ opacity: 1, y: 0 })
    if (iInView) iControls.start({ opacity: 1, x: 0 })

  }, [tControls, iControls, tInView, iInView])
  const photo = getImage(frontmatter.photo)
  return (
    <StyledSection id="testimonials">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <StyledInnerWrapper>
          <motion.div
            className="card card-secondary"
            ref={tRef}
            initial={{ opacity: 0, y: 20 }}
            animate={tControls}
          >
            <div className="card__body">
              <div className="item">
                <div className="number">{frontmatter.textLeftPrefix}</div>
                <div className="text">{frontmatter.textLeft}</div>
              </div>
              <div className="item">
                <div className="number">{frontmatter.textRightPrefix}</div>
                <div className="text">{frontmatter.textRight}</div>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=fUEzTnCbBtE" target="_blank" rel="noreferrer" className="card__footer">
              <span>{frontmatter.bottomText}</span>
            </a>
          </motion.div>
          <motion.div
            className="card card-primary"
            ref={iRef}
            initial={{ opacity: 0, x: 20 }}
            animate={iControls}
          >
            <div className="card__body">
              <Avatar>
                <GatsbyImage loading="lazy" className="photo" alt="Derek Chang photo" image={photo} />
              </Avatar>
              <div className="author">{frontmatter.author}</div>
              <div className="text">{frontmatter.text}</div>
            </div>
            <div className="card__footer">{frontmatter.jobTitle}</div>
          </motion.div>
        </StyledInnerWrapper>
      </StyledContentWrapper>
{/*       <div className="more">
        <StyledLargeButton to={`${slug}/customers/`}>{frontmatter.buttonText}</StyledLargeButton>
      </div> */}
    </StyledSection>
  )
}

Testimonial.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Testimonial