import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import { detectMobileAndTablet, isSSR } from "utils"
import { useOnScreen } from "hooks/"
import ContentWrapper from "styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 3rem 0;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: unset;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 0;
      padding-left: 0;
    }
    .section-title {
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
      padding: 0 2rem;
      margin-top: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`

const StyledClientsWrapper =  styled.div`
  position: relative;
  padding: 2rem 0px 2.5rem;
  overflow: hidden;
`

const StyledItemsWrapper =  styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  max-width: ${({ theme }) => theme.pageWidth};
  margin: 0 auto;
  padding: 0 15px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 2.5rem;
  }
  .item-wrapper {
    color: white;
    width: unset;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    .title {
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
    .description {
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: center;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    .item-wrapper {
      margin: 0 3rem;
      width: 20rem;
    }
  }
`

const StyledClients = styled.div`
  display: flex;
  position: relative;
  animation: marquee 6s linear infinite;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: marquee 20s linear infinite;
  }
  width: 200%;
  .client {
    height: 4rem;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin: auto;
    padding: 1rem 2.2rem;
    .logo {
      margin-right: 0.5rem;
    }
  }
`

const ClientsHome = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { shownItems, clients, items } = exports

  const [shownClients, setShownClients] = useState(shownItems)

  const ref = useRef()
  const onScreen = useOnScreen(ref)

  const iControls = useAnimation()
  const bControls = useAnimation()

  useEffect(() => {
    // If mobile or tablet, show all clients initially
    // Otherwise clients.mdx will determine how many clients are shown
    // (isSSR) is used to prevent error during gatsby build
    if (!isSSR && detectMobileAndTablet(window.innerWidth)) {
      setShownClients(clients.length)
    }
  }, [clients])

  useEffect(() => {
    const sequence = async () => {
      await iControls.start(i => ({
        opacity: 1,
        scaleY: 1,
        transition: { delay: i * 0.1 },
      }))
      await bControls.start({ opacity: 1, scaleY: 1 })
    }
    sequence()
  }, [onScreen, shownClients, iControls, bControls])

  return (
    <StyledSection id="clients">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <StyledClientsWrapper>
          <StyledClients itemCount={clients.length} ref={ref}>
            {clients.slice(0, shownClients).map(({ alt, icon, width, height }, key) => {
              return (
                <motion.div
                  className="client"
                  key={key}
                  custom={key}
                  initial={{ opacity: 0, scaleY: 0 }}
                  animate={iControls}
                >
                  <img
                    alt={alt}
                    style={{width: `${width}rem`}}
                    width={width*20}
                    height={height*20}
                    className="logo lazyload"
                    src="https://hrflow-ai.imgix.net/empty.svg"
                    data-src={`https://hrflow-ai.imgix.net/${icon}.svg`}
                  />
                </motion.div>
              )
            })}
            {clients.slice(0, shownClients).map(({ alt, icon, width, height }, key) => {
              return (
                <motion.div
                  className="client"
                  key={key}
                  custom={key}
                  initial={{ opacity: 0, scaleY: 0 }}
                  animate={iControls}
                >
                  <img
                    alt={alt}
                    style={{width: `${width}rem`}}
                    width={width*20}
                    height={height*20}
                    className="logo lazyload"
                    src="https://hrflow-ai.imgix.net/empty.svg"
                    data-src={`https://hrflow-ai.imgix.net/${icon}.svg`}
                  />
                </motion.div>
              )
            })}
          </StyledClients>
        </StyledClientsWrapper>
        <StyledItemsWrapper>
          {items.map(({title, description}, key) => {
            return (
              <motion.div className="item-wrapper">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
              </motion.div>
            )
          })}
        </StyledItemsWrapper>
      </StyledContentWrapper>
    </StyledSection>
  )
}

ClientsHome.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          clients: PropTypes.array.isRequired,
          shownItems: PropTypes.number.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default ClientsHome

