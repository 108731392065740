import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ContentWrapper from "styles/contentWrapper"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])

library.add(...iconList)

const spot1 = 'https://hrflow-ai.imgix.net/backgrounds/spot.svg'
const spot2 = 'https://hrflow-ai.imgix.net/backgrounds/mini-spot.svg'
const spot3 = 'https://hrflow-ai.imgix.net/backgrounds/circle-spot.svg'

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 3rem 0;
  background: linear-gradient(
  357.83deg
  ,rgba(0,207,225,0) 1.57%,rgba(0,207,225,.06) 40.64%,rgba(255,255,255,.02) 99.79%);
      mix-blend-mode: normal;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    background-image: url(${spot1}), url(${spot2}), url(${spot3});
    background-size: 16rem 22.5rem, 1.5rem 2rem, 2.55rem 2.55rem;
    background-position: 5% 45%, 5% 27%, 98% 98%;
    background-repeat: no-repeat;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
`


const StyledItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 1rem;
  grid-auto-rows: minmax(261px, auto);
  justify-items: center;
  .item {
    cursor: pointer;
    // max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-radius: 1rem;
    transition: all 0.1s ease;
    &:hover  {
      transition: all 0.1s ease;
      .logo-wrapper {
        background: transparent;
        .image.colored {
          display: none;
          transition: all 0.1s ease;
        }
        .image.white {
          display: block;
          transition: all 0.1s ease;
        }
      }
      h3, .text, .link {
        color: white!important;
      }


    }
    &:first-of-type {
      border: 2px solid #30BFD9;
      .link {
        font-weight: 600;
        text-decoration: underline;
        color: #30BFD9;
      }
      &:hover {
        background #30BFD9;
      }
    }
    &:nth-of-type(2) {
      border: 2px solid #80D930;
      .link {
        font-weight: 600;
        text-decoration: underline;
        color: #80D930;
      }
      &:hover {
        background #80D930;
      }
    }
    &:nth-of-type(3) {
      border: 2px solid #EF884A;
      .link {
        font-weight: 600;
        text-decoration: underline;
        color: #EF884A;
      }
      &:hover {
        background #EF884A;
      }
    }
    padding: 2rem;
    .item-content {
      justify-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .logo-wrapper {
      background: #EDFAF9;
      border-radius: 50%;
      padding: 1rem;  
      width: 4.5rem;
      height: 4.5rem;
      font-size: 4.5rem;
      display: flex;
      justify-content: flex-start;
      color: #34cbe6;
      .image.colored {
        display: block;
        transition: all 0.1s ease;
      }
      .image.white {
        display: none;
        transition: all 0.1s ease;
      }
    }
    h3 {
      margin: 0;
      margin: 0.75rem 0;
      margin-bottom: 0.2rem;
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
    .highlghited {
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.75rem;
    }
    .text {
      color: #5C676A;
    }
    a {
      icon {
        margin-left: 0.3rem;
        vertical-align: middle;    
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      &:nth-of-type(1), &:nth-of-type(4) {
        justify-self: start;
      }
      &:nth-of-type(2), &:nth-of-type(5) {
        justify-self: center;      
      }
      &:nth-of-type(3), &:nth-of-type(6) {
        justify-self: end;      
      }
      // max-width: 413px;
    }
    /* transition: all .3s ease;
    &:hover {
      transform: scale(1.05) translate(0, -10px)!important;
      transition: transform .3s;
    } */
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-rows: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-template-columns: repeat(1, 1fr);
    .item {
      max-width: 90%;
    }
    grid-gap: 2rem;
  }
`

const Showcases = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { showcases } = exports

  // Only trigger animations if the intro is done or disabled
  const iControls = useAnimation()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    const sequence = async () => {
      if (inView) {
        await iControls.start(i => ({
          opacity: 1,
          scaleY: 1,
          transition: { delay: i * 0.2 },
        }))
      }
    }
    sequence()
  }, [inView, iControls])

  return (
    <StyledSection id="showcases">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <StyledItems ref={ref}>
          {showcases.map(({ name, logo, logoHover, description, button, link }, key) => {
            const image = getImage(logo)
            const imageHover = getImage(logoHover)
            return (
              <motion.div
                className="item"
                key={key}
                custom={key}
                initial={{ opacity: 0, scaleY: 0 }}
                animate={iControls}
              >
                <Link to={link}>
                  <div>
                    <div className="logo-wrapper">
                      <GatsbyImage className="image colored" alt="Worfkflow primary background" image={image} />
                      <GatsbyImage className="image white" alt="Worfkflow white background" image={imageHover} />
                    </div>
                    <h3>{name}</h3>
                    <div className="text mb-075">{description}</div>
                  </div>
                  <span className="link">{button}</span>
                </Link>
              </motion.div>
            )
          })}
        </StyledItems>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Showcases.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          items: PropTypes.array.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Showcases
