import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'

const StyledButton = styled(Link)`
width: auto;
height: auto;
white-space: nowrap;
text-align: center;
border-radius: 30px;
transition: 20ms ease-out;
font-size: 1rem;
padding: 4px 13px 6px 13px;
margin: 0;

`

const RoundedButton = (props) => {

  return (
    <StyledButton
      className={props.className}
      to={props.link}
    >
      {props.label} <FontAwesomeIcon className="ml-05" icon={faChevronRight} />
    </StyledButton>
  );
}

export default RoundedButton