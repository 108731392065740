import React, { useState, useEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { detectMobileAndTablet, isSSR } from "utils"
import ContentWrapper from "styles/contentWrapper"
import Context from "context/"

const spot1 = 'https://hrflow-ai.imgix.net/backgrounds/spot.svg'
const spot2 = 'https://hrflow-ai.imgix.net/backgrounds/mini-spot.svg'
const spot3 = 'https://hrflow-ai.imgix.net/backgrounds/circle-spot.svg'


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
  background-image: url(${spot1}), url(${spot2}), url(${spot3});
  background-size: 16rem 22.5rem, 1.5rem 2rem, 2.55rem 2.55rem;
  background-position: 5% 45%, 5% 27%, 98% 98%;
  background-repeat: no-repeat;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      color: ${({ theme }) => theme.colors.primary};
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 1rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .section-subtitle {
      color: ${({ theme }) => theme.colors.secondary};
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`

const StyledMedias = styled.div`
  display: flex;
  grid-auto-flow: column;
  padding: 0 2.5rem 1.25rem 2.5rem;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    overflow: visible;
    padding: 0;
  }

  .media {
    margin: auto;
    width: 17.55rem;
    height: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    background: #fff;
    border: 0.1rem solid #d8ecf3;
    box-sizing: border-box;
    border-radius: 0.5rem;
    cursor: pointer;
    .logo {
      width: 5rem;
      &.nohover {
        display: block;
      }
      &.hover {
        display: none;
      }
    }
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      .logo {
        width: 7rem;
      }
    }
    &:hover {
      background: ${({ theme }) => theme.colors.secondary};
      .logo.nohover {
        display: none;
      }
      .logo.hover {
        display: block;
      }
    }
  }
`

const Medias = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { shownItems, medias } = exports
  const { isIntroDone } = useContext(Context).state

  const [shownMedias, setShownMedias] = useState(shownItems)

  const iControls = useAnimation()
  const bControls = useAnimation()
  const { ref, inView, entry } = useInView({threshold: 0});
  useEffect(() => {
    // If mobile or tablet, show all medias initially
    // Otherwise medias.mdx will determine how many medias are shown
    // (isSSR) is used to prevent error during gatsby build
    if (!isSSR && detectMobileAndTablet(window.innerWidth)) {
      setShownMedias(medias.length)
    }
  }, [medias])

  useEffect(() => {
    if (inView) {
      const sequence = async () => {
        await iControls.start(i => ({
          opacity: 1,
          scaleY: 1,
          transition: { delay: i * 0.1 },
        }))
        await bControls.start({ opacity: 1, scaleY: 1 })
      }
      sequence()
    }
  }, [inView, shownMedias, iControls, bControls])

  return (
    <StyledSection id="medias">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <h2 className="section-subtitle">{frontmatter.subtitle}</h2>
        <StyledMedias itemCount={medias.length} ref={ref}>
          {medias.slice(0, shownMedias).map(({ alt, logo, logoHover, link }, key) => {
            const image = getImage(logo)
            const imageHover = getImage(logoHover)
            return (
              <motion.a
                className="media"
                key={key}
                custom={key}
                initial={{ opacity: 0, scaleY: 0 }}
                animate={iControls}
                href={link}
                target="_blank"
                rel="nofollow noopener"
              >
                <GatsbyImage
                  className="logo nohover"
                  alt={alt}
                  image={image}
                  loading="lazy"
                />
                <GatsbyImage
                  className="logo hover"
                  alt={alt}
                  image={imageHover}
                  loading="lazy"
                />
              </motion.a>
            )
          })}
        </StyledMedias>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Medias.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          medias: PropTypes.array.isRequired,
          shownItems: PropTypes.number.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Medias
