import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import Underlining from "styles/underlining"
import SplashScreen from "components/splashScreen"
import { lightTheme, darkTheme } from "styles/theme"
import thumbnail from "content/images/thumbnail.png"
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background-image: url("https://hrflow-ai.imgix.net/backgrounds/main-spot.svg"),
  url("https://hrflow-ai.imgix.net/gradient-bg.webp");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 29rem 25rem, cover;
  background-position: 68% 0, center;
  padding-bottom: 3.5rem;
  padding-top: 7.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-bottom: 2rem;
    padding-top: 7.5rem;
  }

`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .video {
      margin-top: 2rem;
      .modal__video {
        margin-top: 0.8rem;
        border-radius: 0.3rem;
        iframe {
          border-radius: 0.3rem 0.3rem 0 0;
          height: 24rem;
        }
      }
      .thumbnail {
        position: relative;
        .thumbnail__image {
          width: 100%;
          /* border: 1px solid #00C8DD;
          border-radius: 6px; */
        }
      }
      .video-trial__container {
        position: absolute!important;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex!important;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: 2rem;
        padding-right: 2rem;
        .wistia_click_to_play {
          width: unset!important;
          height: unset!important;
        }
        .video-trial {
          display: flex;
          align-items: flex-start;
          background: white;
          border-radius: 3rem;
          cursor: pointer;
          color: #009DC5;
          padding: 8px 15px;
          border: 2px solid #009DC5;
          -webkit-animation: pulse 1.8s infinite;
          animation: pulse 1.8s infinite;
        }
        .video-trial .play-image {
          height: 1.25rem;
          width: 1.25rem;
          margin-right: .25rem;
        }
        .video-trial .video-text {
          font-size: .7rem;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2rem;
          letter-spacing: .065rem;
          text-transform: uppercase;
        }
      }
    }

    .intro {
      text-align: center;
      .title {
        margin-bottom: 1rem;
        color: white;
      }
      .subtitle {
        color: white;
        padding: 0;
        font-weight: 300;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        text-align: left;
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      align-items: center;
      .video {
        margin-top: 0;
      }
    }
  }
`

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: flex-start;
  }
  .cta-btn {
    width: auto;
    height: auto;
    font-weight: 700;
    white-space: nowrap;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.white};
    transition: 20ms ease-out;
    font-size: 1rem;
    padding: 0.7rem 1rem 0.8rem 1rem;
    margin: 0;
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
  .cta-btn.empty {
    border: 1px solid ${({ theme }) => theme.colors.white};
    background: transparent;
    color: ${({ theme }) => theme.colors.white};
    margin-right: 1rem;
    &:hover {
      background-color: rgba(36, 204, 212, 0.4);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-direction: column;
    .cta-btn:first-of-type {
      margin-bottom: 1rem;
    }
    .cta-btn.empty {
      margin-right: 0;
    }
  }
`

const AnimatedUnderlining = motion.custom(Underlining)

const Hero = ({ content }) => {
  const { frontmatter } = content[0].node
  const { isIntroDone, darkMode } = useContext(Context).state
  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const sControls = useAnimation()
  const vControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      await gControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.1 },
      })
      await sControls.start({
        opacity: 1,
        x: 0,
      })
      if(isIntroDone) {
        await vControls.start({
          opacity: 1,
          x: 0,
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, gControls, sControls, vControls])
  
/*   useEffect(() => {
    const script = document.createElement("script");
    const script1 = document.createElement("script1");
    script1.src = "https://fast.wistia.com/em1bed/medias/4yf1bscblu.jsonp";
    script1.defer = true;
    script1.async = false;

    script.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script.defer = true;
    script.async = false;
    document.body.appendChild(script);
    document.body.appendChild(script1);
    return function cleanup() {
      document.body.removeChild(script);
      document.body.removeChild(script1);
    }; 
  }, [])*/

  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <StyledContentWrapper>
        <div className="intro">
          <motion.div
            initial={{ opacity: 1, y: 20 }}
            animate={gControls}
            data-testid="animated-heading"
          >
            <h1 className="title">
              {frontmatter.greetings} <br />
              {frontmatter.title}
            </h1>
            <h2 className="subtitle">
              {frontmatter.subtitlePrefix}
              <br />
              {frontmatter.subtitle}
            </h2>
          </motion.div>
          <motion.div initial={{ opacity: 0, x: 20 }} animate={sControls}>
            <StyledButtons>
              <Link className="cta-btn empty" to="/signup/">
                get started
              </Link>
              <a className="cta-btn" href="https://labs.hrflow.ai">
                See ai in action
              </a>
            </StyledButtons>
          </motion.div>
        </div>
        <div className="video">
          {/* <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}><div className="wistia_responsive_wrapper" style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}><div className="wistia_embed wistia_async_4yf1bscblu videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>&nbsp;</div></div></div> */}
      {/*     <div className="modal__video">
            <iframe
              width="100%"
              height="100%"
              src="//www.youtube.com/embed/cG8wI64_gXE"
              frameBorder="0"
              allowFullScreen
              loading="lazy"
            >
            </iframe>
          </div> */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={vControls}
            data-testid="animated-heading"
            className="thumbnail"
          >
            <img className="thumbnail__image" width="700" height="442" alt="workflow-img" src="https://hrflow-ai.imgix.net/destinations2.svg" />
           {/*  <StaticImage
              src="https://hrflow-ai.imgix.net/workflows.png"
              alt="thumbnail image"
              placeholder="blurred"
              className="thumbnail__image"
            /> */}
         {/*    <span className="video-trial__container wistia_embed wistia_async_4yf1bscblu popover=true popoverContent=link" style={{display: 'inline', position:'relative'}}>
              <a href="#" className="video-trial">
                <img src="https://hrflow-ai.imgix.net/play.svg" alt="play image" className="play-image" /><div className="video-text text-uppercase">see how it works</div>
              </a>
            </span> */}
          </motion.div>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
