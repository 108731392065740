import React, { useRef, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckCircle, faSmile, faCodeBranch} from '@fortawesome/free-solid-svg-icons'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import Tabs from 'components/common/tabs'

import RoundedButton from "components/common/buttons/roundedButton";

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 3rem 1rem;
  color: ${({ theme }) => theme.colors.white};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .tab-list {
      justify-content: center;
    }
    .slidingVertical{
      display: inline;
      text-indent: 8px;
    }
    .slidingVertical span {
      animation: topToBottom 12.5s linear infinite 0s;
      -ms-animation: topToBottom 12.5s linear infinite 0s;
      -webkit-animation: topToBottom 12.5s linear infinite 0s;
      opacity: 0;
      overflow: hidden;
        position: absolute;
        text-decoration: underline;
    }
    .slidingVertical span:nth-child(2){
      animation-delay: 2.5s;
      -ms-animation-delay: 2.5s;
      -webkit-animation-delay: 2.5s;
    }
    .slidingVertical span:nth-child(3){
      animation-delay: 5s;
      -ms-animation-delay: 5s;
      -webkit-animation-delay: 5s;
    }
    
    .slidingVertical span:nth-child(4){
      animation-delay: 7.5s;
      -ms-animation-delay: 7.5s;
      -webkit-animation-delay: 7.5s;
    }
    
    .slidingVertical span:nth-child(5){
      animation-delay: 10s;
      -ms-animation-delay: 10s;
      -webkit-animation-delay: 10s;
    }
    
    .slidingVertical span:nth-child(6){
      animation-delay: 12.5s;
      -ms-animation-delay: 12.5s;
      -webkit-animation-delay: 12.5s;
    }

    @keyframes topToBottom {
      0% { opacity: 0; }
      5% { opacity: 0; transform: translateY(-20px); }
      10% { opacity: 1; transform: translateY(0px); }
      25% { opacity: 1; transform: translateY(0px); }
      30% { opacity: 0; transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }

    @-moz-keyframes topToBottom {
      0% { opacity: 0; }
      5% { opacity: 0; -moz-transform: translateY(-20px); }
      10% { opacity: 1; -moz-transform: translateY(0px); }
      25% { opacity: 1; -moz-transform: translateY(0px); }
      30% { opacity: 0; -moz-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }
    @-webkit-keyframes topToBottom {
      0% { opacity: 0; }
      5% { opacity: 0; -webkit-transform: translateY(-20px); }
      10% { opacity: 1; -webkit-transform: translateY(0px); }
      25% { opacity: 1; -webkit-transform: translateY(0px); }
      30% { opacity: 0; -webkit-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }
    @-ms-keyframes topToBottom {
      0% { opacity: 0; }
      5% { opacity: 0; -ms-transform: translateY(-20px); }
      10% { opacity: 1; -ms-transform: translateY(0px); }
      25% { opacity: 1; -ms-transform: translateY(0px); }
      30% { opacity: 0; -ms-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }
    .section-title {
      color: white;
      text-align: center;
      margin-top: 0;
      margin-bottom: 1rem;
      padding: 0 1rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .section-subtitle {
      color: white;
      text-align: center;
      margin-top: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .label {
      font-size: 1.2rem;
      line-height: 1.75rem;
      letter-spacing: -0.05rem;
      color: #47b881;
      margin: auto;
      margin-bottom: 1.5rem;
    }
  }
`

const StyledCorporates = styled.div`
  display: flex;
  flex-direction: column-reverse;
  .tab-list {
    margin-top: 3rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    display: grid;
    grid-gap: 3rem;
    max-width: 68rem;
    width: 100%;
    margin: auto;
  }
  .corporates-image {
    width: 100%;
    height: auto;
  }
`

const StyledDevelopers = styled.div`
  margin-top: 3rem;
  display: grid;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    margin: auto;
    max-width: 60rem;
    width: 100%;
  }
`

const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .label {
    font-size: 1.2rem;
    line-height: 1.75rem;
    letter-spacing: -0.05rem;
    color: #47b881;
    margin-bottom: 1.5rem;
  }
  h2 {
    color: white;
    margin-bottom: 1.5rem;
    padding: 0;
  }
  .headline {
    max-width: 25rem;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: -.005rem;
  }

  .list {
    list-style: none;
    margin: 0;
    margin: 2rem 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0.5rem;
    grid-template-rows: minmax(10px, auto);
    a {
      color: ${({ theme }) => theme.colors.tertiary};
    }
  }
`

const StyledCodeEditor = styled.div`
  display: none;
  box-shadow: 27.1px 62.5px 125px -25px rgba(50,50,93,.5), 16.2px 37.5px 75px -37.5px rgba(0,0,0,.6);
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  background: #32325d;

  pre {
    margin-bottom: 0;
  }

  pre.code {
    font-family: Source Code Pro,Consolas,Menlo,monospace;
    white-space: pre-line;
    line-height: 1.4rem;
    font-size: 0.85rem;
    height: 21rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 10px;
    border-top-left-radius: .655rem;
    border-top-right-radius: .655rem;
    // background: linear-gradient(90deg,#32325d,rgba(50,50,93,0));
    padding: 1rem;
    color: #e6ebf1;
  }

  pre.code .string {
    color: #ffa956;
  }

  pre.code .function {
    color: #8095ff;
  }

  pre.code .comment {
    color: #6b7c93;
  }
  pre.code .operator {
    color: #ff9d3d;
  }

  pre.code .number {
    color: #ffd96a;
  }

  pre.code:before {
    counter-reset: listing
  }

  pre.code code {
    counter-increment: listing
  }

  pre.code code::before {
    content: counter(listing) "    "
  }

  &::-webkit-scrollbar {
    width: .6rem
  }

  &::-webkit-scrollbar-thumb {
    border-radius: .225rem;
    background-color: #e6edef
  }

  code, kbd, samp, pre {
    display: block;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
  }
`

const StyledCodeDivider = styled.div`
  height: 1.5rem;
  background: rgb(0, 73, 93);
  display: none;
  color: white;
  font-size: small;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: space-between;
  .icon {
    margin-right: 0.5rem;
  }
  span {
    margin-right: 0.7rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const API = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { corporates, developers } = exports
  const { isIntroDone } = useContext(Context).state
  const [ tRef, tInView, tEntry ] = useInView({threshold: 0});
  const [ iRef, iInView, iEntry ] = useInView({threshold: 0});
  const [ cRef, cInView, cEntry ] = useInView({threshold: 0});
  const [ dRef, dInView, dEntry ] = useInView({threshold: 0});

  const tControls = useAnimation()
  const iControls = useAnimation()
  const dControls = useAnimation()
  const cControls = useAnimation()

  useEffect(() => {
    const sequence = async () => {
      if (tInView) tControls.start({ opacity: 1, y: 0 })
      if (iInView) iControls.start({ opacity: 1, y: 0 })
      if (cInView) cControls.start({ opacity: 1, y: 0 })
      if (dInView) dControls.start({ opacity: 1, y: 0 })
    }
    sequence()
  }, [tControls, iControls, cControls, dControls, tInView, iInView, cInView, dInView])

  const imageParsing = getImage(frontmatter.imageParsing)
  const imageScoring = getImage(frontmatter.imageScoring)

  // Only trigger animations if the intro is done or disabled

  return (
    <StyledSection id="layers">
      <StyledContentWrapper>
      <div className="label">{frontmatter.hintCor}</div>
      <h2 className="section-title">{frontmatter.titleCor}</h2>
      <div className="section-subtitle">{frontmatter.subtitleCor}</div>
        <StyledCorporates>
          <motion.div
            ref={iRef}
            initial={{ opacity: 0, y: 20 }}
            animate={iControls}
          >
            <Tabs minHeight={"38rem"} maxHeight={"38rem"}> 
              <div label="WITH HRFLOW.AI"> 
                <img
                  alt="Corporate image flow"
                  width="984"
                  height="552.47"
                  className="lazyload corporates-image"
                  src="https://hrflow-ai.imgix.net/empty.svg"
                  data-src="https://hrflow-ai.imgix.net/corporate.svg"
                />
              </div> 
              <div label="WITHOUT HRFLOW.AI"> 
                <img
                  alt="Corporate image schema"
                  width="984"
                  height="552.47"
                  className="lazyload corporates-image"
                  src="https://hrflow-ai.imgix.net/empty.svg"
                  data-src="https://hrflow-ai.imgix.net/corporate2.svg"
                />
              </div>  
            </Tabs> 
          </motion.div>
 
{/*           <motion.div
             ref={tRef}
             initial={{ opacity: 0, y: 20 }}
             animate={tControls}
          >
            <StyledDetails>
              <div className="label">{frontmatter.label1}</div>
              <h2>{frontmatter.title1}</h2>
              <div className="headline">
                {frontmatter.subtitle1}
                <span className="slidingVertical">
                  <span>HRIS.</span>
                  <span>HCM.</span>
                  <span>Analytics and BI platforms.</span>
                  <span>ERP.</span>
                  <span>ATS.</span>
                </span>
              </div>
              <ul className="list">
                {corporates.map(({ name, link }, key) => (
                  <li key={key}>
                    <a href={link} target="_blank" rel="nofollow noopener">
                      <FontAwesomeIcon className="mr-1" icon={faCheckCircle} />
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
              <RoundedButton
                className="button-tertiary"
                label="Automate with HrFlow.ai"
                link={frontmatter.corporatesLink}
              />
            </StyledDetails>
          </motion.div> */}
        </StyledCorporates>
      </StyledContentWrapper>
    </StyledSection>
  )
}

API.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          corporates: PropTypes.array.isRequired,
          developers: PropTypes.array.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default API
