import React, { useRef, useContext, useEffect } from "react"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import goLogo from 'content/developers/steps/logos/go.svg'
import javaLogo from 'content/developers/steps/logos/java.svg'
import dotNetLogo from 'content/developers/steps/logos/dotnet.svg'
import nodeLogo from 'content/developers/steps/logos/node.svg'
import phpLogo from 'content/developers/steps/logos/php.svg'
import pythonLogo from 'content/developers/steps/logos/python.svg'
import rubyLogo from 'content/developers/steps/logos/ruby.svg'

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 1rem;
  .section-title {
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
  .label {
    font-size: 1.2rem;
    line-height: 1.75rem;
    letter-spacing: -0.05rem;
    color: #47b881;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  p {
    margin-top: 0;
    text-align: center;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
`

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  div {
    max-width: 400px;
    .image {
      width: 100%;
    }

    h4 {
      margin-bottom: 1rem;
      text-align: center;
      margin-top: 2rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-top: 0;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Ul = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 5rem;
  li {
    display: flex; 
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    img {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`




const Apidev = ({ content }) => {
  const { frontmatter, body } = content[0].node
  const image1 = getImage(frontmatter.image1)
  const image2 = getImage(frontmatter.image2)
  const image3 = getImage(frontmatter.image3)
  return (
    <StyledSection id="code">
      <StyledContentWrapper>
        <div className="label">{frontmatter.label}</div>
        <h2 className="section-title">{frontmatter.title}</h2>
        <MDXRenderer>{body}</MDXRenderer>
        <StyledInner>
          <div>
            <h4>{frontmatter.step1}</h4>
            <GatsbyImage className="image" alt={frontmatter.step1} image={image1} />
          </div>
          <div>
            <h4>{frontmatter.step2}</h4>
            <GatsbyImage className="image" alt={frontmatter.step2} image={image2} />
          </div>
          <div>
            <h4>{frontmatter.step3}</h4>
            <GatsbyImage className="image" alt={frontmatter.step3} image={image3} />
          </div>
        </StyledInner>
        <Ul>
          <li><span>GO</span><img src={goLogo} alt="go logo" /></li>
          <li><span>JAVA</span><img src={javaLogo} alt="java logo" /></li>
          <li><span>NODE</span><img src={nodeLogo} alt="node logo" /></li>
          <li><span>PHP</span><img src={phpLogo} alt="php logo" /></li>
          <li><span>PYTHON</span><img src={pythonLogo} alt="python logo" /></li>
          <li><span>RUBY</span><img src={rubyLogo} alt="ruby logo" /></li>
          <li><span>.NET</span><img src={dotNetLogo} alt="dotNet logo" /></li>
        </Ul>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Apidev